<template>
  <div class="bets-tool">
    <CustomHeader
      text="Apostadores"
      :background="colorsConfig.headerBackground"
      :borderColor="colorsConfig.header2Background"
      :icon="colorsConfig.logo"
    />
    <div class="bets-tool__body">
      <div class="bets-tool__body__title">
        <img src="/icons/icn-apostador.svg" alt="logo" />
        <div>
          {{ betToolTitle }}
        </div>
      </div>
      <div class="bets-tool__body__buttons">
        <div v-for="section in sections" :key="section.id">
          <ButtonSectionSelect
            :id="section.id"
            :name="section.name"
            :isActive="section.id === selectedSection.id"
            @click="onSectionButtonClicked"
          />
        </div>
      </div>
      <div class="bets-tool__body__section">
        <SectionContainer
          :title="selectedSection.name"
          :isBordered="selectedSection.isBordered"
          :hasHeader="selectedSection.hasHeader"
          :isFullWidth="selectedSection.isFullWidth"
        >
          <KeepAlive include="RightBet,Parlay">
            <Component :is="selectedSection.componentName" :is-premium-user="isPremiumUser" :seasons="seasons" />
          </KeepAlive>
        </SectionContainer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { freePicksRightBet, freePicksParlay, freePicksSimulator, freePicksPersonalized } from '@/utils/analytics';

export default {
  name: 'BetsTool',
  components: {
    ButtonSectionSelect: () => import('@/components/Sections/GolTipster/ButtonSectionSelect'),
    CustomHeader: () => import('@/components/Elements/CustomizableHeader'),
    SectionContainer: () => import('@/components/Sections/GolTipster/SectionContainer'),
    Parlay: () => import('@/components/Sections/GolTipster/Parlay'),
    Personalizado: () => import('@/components/Sections/GolTipster/Personalizado'),
    RightBet: () => import('@/components/Sections/GolTipster/RightBet'),
    Simulador: () => import('@/components/Sections/BetTool/TipGolStats'),
  },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    /**
     * Seasons objects to fetch games
     * {Array<{id: number, type: number}>} seasons
     */
    seasons: {
      type: Array,
      default: () => [],
    },
    colorsConfig: {
      type: Object,
      default: () => {
        return {
          logo: 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png',
          color: '#FFFFFF',
          background: '#242424',
          colorheader: '#000000',
          backgroundheader: '#CCE8B5',

          headerBackground: '#162f4b',
          headerColor: '#FFFFFF',
          headerBorderColor: '#6EB913',

          header2Background: '#3b3b3b',
          header2Color: '#FFFFFF',

          subHeaderBackground: '#cce8b5',
          subHeaderColor: '#353535',
          //bettool
          headerBetToolBackground: '#242424',
          subheaderBetToolBackground: '#151515',

          type: 1,
          team_id: 0,
          categories: 'all',
        };
      },
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          name: 'Apuesta\nderecha',
          componentName: 'RightBet',
          isBordered: true,
          hasHeader: true,
          eventAnalytics: () =>
            freePicksRightBet({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
        {
          id: 2,
          name: 'Parlay',
          componentName: 'Parlay',
          isBordered: true,
          hasHeader: true,
          eventAnalytics: () =>
            freePicksParlay({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
        {
          id: 3,
          name: 'Simulador',
          componentName: 'Simulador',
          isBordered: false,
          hasHeader: false,
          isFullWidth: true,
          eventAnalytics: () =>
            freePicksSimulator({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
        {
          id: 4,
          name: 'Personalizado',
          componentName: 'Personalizado',
          isBordered: false,
          hasHeader: false,
          isFullWidth: true,
          eventAnalytics: () =>
            freePicksPersonalized({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
      ],
      selectedSection: {},
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    betToolTitle() {
      return this.isPremiumUser ? 'Picks GolStats' : 'Free Picks';
    },
  },
  methods: {
    ...mapMutations('general', ['setSeccionBetsGolTipster']),
    onSectionButtonClicked(sectionId) {
      if (sectionId !== 0) {
        this.selectedSection = this.sections.find(section => section.id === sectionId);
        this.setSeccionBetsGolTipster(this.selectedSection.id);
        this.selectedSection.eventAnalytics();
      }
    },
  },
  beforeMount() {
    this.selectedSection = this.sections[0];
  },
};
</script>

<style scoped lang="scss">
.bets-tool {
  width: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2.25em 0;

      & > img {
        height: 2.75em;
        width: 2.75em;
        margin: 0.5em;
      }

      & > div {
        padding-top: 0.2em;
        font-size: 2.9em;
        font-family: Circular-Std-Bold, sans-serif;
      }

      @media screen and (max-width: 380px) {
        margin: 1.8em 0;
        font-size: 0.8rem !important;
      }

      @media screen and (max-width: 350px) {
        font-size: 0.7rem !important;
      }
    }

    &__buttons {
      display: flex;

      & > div {
        margin: 0 0.5rem;

        @media screen and (max-width: 744px) {
          flex-grow: 1;
        }

        @media screen and (max-width: 480px) {
          margin: 0 0.2rem;
        }
      }
    }

    &__section {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
</style>
